import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import SignIn from "./components/auth/Signin";
import UserHomePage from "./components/auth/UserHomePage";
import Product from "./components/Product";
import About from "./components/About";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div className="min-h-screen bg-stone-300 flex flex-col">
        {/* Title Bar */}
        <div className="bg-stone-300 shadow-md">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center py-4">
              <Link to="/" className="font-bold text-xl text-gray-800">HomePro</Link>
              <div className="flex space-x-4">
                <Link to="/product" className="text-gray-600 hover:text-gray-800">Product</Link>
                <Link to="/about" className="text-gray-600 hover:text-gray-800">About</Link>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <Routes>
          <Route path="/signin" element={isLoggedIn ? <UserHomePage onLogout={handleLogout} /> : <SignIn onLogin={handleLogin} />} />
          <Route path="/" element={isLoggedIn ? <UserHomePage onLogout={handleLogout} /> : <SignIn onLogin={handleLogin} />} />
          <Route path="/product" element={<Product />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;