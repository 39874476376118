import React from "react";


const UserReminders = () => {
    return (
    <div>
        <h1>Your reminders come here</h1>
        <p> We are working on it</p>
    </div>
    );
}

export default UserReminders;
