import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import "../../styles/global.css";
import UserHomeDetails from "../HomePage/UserHomeDetails";
import UserAIAssistant from "../HomePage/UserAIAssitant";
import UserContacts from "../HomePage/UserContacts";
import UserReminders from "../HomePage/UserReminders";
import UserHomeProjects from "../HomePage/UserHomeProjects";

const UserHomePage = ({ onLogout }) => {
  const [signedInUser, setSignedInUser] = useState(null);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setSignedInUser(user);
      } else {
        setSignedInUser(null);
      }
    });
    return () => {
      listen();
    };
  }, []);
  const logOut = () => {
    signOut(auth);
    onLogout();
  };

  return (

      <div class='bg-stone-300 w-screen h-screen'>
        <h1 class="text-blue-900/75">Welcome to your Home Page</h1>
        <div class="flex">

        <div class="w-1/2 border-2 border-solid border-blue-900 rounded-l-lg mr-2">
          <UserHomeDetails />
        </div>
        <div class="w-1/2 border-2 border-solid border-blue-900 rounded-l-lg ml-2">
          <UserAIAssistant />
        </div>
        </div>

        {signedInUser ? (
          <>
            <p>{`Logged In as ${signedInUser.email}`} </p>
            <>
              <button onClick={logOut}>Log Out</button>
            </>
          </>
        ) : (
          <p>{`Logged Out`}</p>
        )}
      </div>

  );
};

export default UserHomePage;
