import React, { useState } from "react";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

const SignIn = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((useCred) => {
        onLogin();
        console.log(useCred);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const inputStyle = {
    width: '100%',
    padding: '12px',
    border: '2px solid black',
    borderRadius: '8px',
    fontSize: '16px',
    marginBottom: '16px'
  };

  return (
    <div className="min-h-screen bg-stone-300 flex flex-col">
      
      {/* Main Content */}
      <div className="flex-grow flex justify-center items-center px-4">
        <div className="w-full max-w-6xl flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-12">
          {/* Image/Logo */}
          <div className="w-full md:w-1/2 flex justify-center">
            <img 
              src="./homepage.jpeg" 
              alt="Homeowner Tech" 
              className="w-full max-w-md h-auto object-contain"
            />
          </div>

          {/* Login form */}
          <div className="w-full md:w-1/2 max-w-md">
            <form onSubmit={signIn} className="w-full">
              <div>
                <input
                  id="email"
                  name="email"
                  type="text"
                  style={inputStyle}
                  placeholder="Email/Phone"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  id="password"
                  name="password"
                  type="password"
                  style={inputStyle}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="flex justify-center">
                  <button className="px-8 py-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300 shadow-md">
                    Login
                  </button>
                </div>
              </div>
            </form>
            <p className="mt-6 text-center text-gray-600">
              Curious or want to register? Write to{" "}
              <span className="font-semibold">vikrant@home-owners.tech</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;