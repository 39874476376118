import React from 'react';

const About = () => {
  const sectionStyle = "mb-12";
  const headingStyle = "text-2xl font-bold mb-4 text-gray-800";
  const subheadingStyle = "text-xl font-semibold mb-2 text-gray-700";
  const paragraphStyle = "mb-4 text-gray-600";
  const linkStyle = "text-blue-600 hover:underline";
  const teamMemberStyle = "mb-8 flex flex-col md:flex-row items-center md:items-start gap-6";
  const imageStyle = "w-48 h-48 rounded-full object-cover mb-4 md:mb-0";

  return (
    <div className="min-h-screen bg-stone-300 flex justify-center items-center px-4 py-8">
      <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">About Us</h1>

        <div className={sectionStyle}>
          <p className={paragraphStyle}>
            At Home Pro, we're not just business partners—we're a team that came together in the most fitting way possible. When Vikrant bought his first home, Seb was the realtor making the deal happen, and Nicole was the inspector crawling through the attic. What started as a successful home purchase turned into a shared vision: helping homeowners avoid the stress and confusion that comes with maintaining a home.
          </p>
        </div>

        <div className={sectionStyle}>
          <h2 className={headingStyle}>Meet the Team</h2>

          <div className={teamMemberStyle}>
            <img src={`${process.env.PUBLIC_URL}/vikrant.jpeg`} alt="Vikrant" className={imageStyle} />
            <div>
              <h3 className={subheadingStyle}>Vikrant (he/him)</h3>
              <h4 className="text-lg font-medium mb-2 text-gray-700">CEO (Chief Everything Officer)</h4>
              <p className={paragraphStyle}>
                The mastermind behind the tech and the wearer of many hats, Vikrant leads everything from product strategy to making sure we've got enough coffee in the office. With over 20 years in the tech industry, Vikrant knows a thing or two about solving complex problems. And as a new homeowner himself, he's using that experience to build Home Pro, helping you stay on top of all the details—without breaking a sweat.
              </p>
              <a href="https://www.linkedin.com/in/vkrntsngh/" target="_blank" rel="noopener noreferrer" className={linkStyle}>LinkedIn Profile</a>
            </div>
          </div>

          <div className={teamMemberStyle}>
            <img src={`${process.env.PUBLIC_URL}/Nicole.jpeg`} alt="Nicole" className={imageStyle} />
            <div>
              <h3 className={subheadingStyle}>Nicole (she/her)</h3>
              <h4 className="text-lg font-medium mb-2 text-gray-700">Co-Founder & Chief Product Officer</h4>
              <p className={paragraphStyle}>
                Nicole doesn't just love homes—she's obsessed with every nook and cranny. As our Chief Product Officer, Nicole ensures that Home Pro covers every possible need a homeowner might have. After more than a decade in architecture and home inspection, she's here to make sure your home is as sound as her advice. Fun fact: when she's not helping build the perfect app or inspecting homes, Nicole's probably at a Timbers or Thorns match, or exploring sunny spots around the globe.
              </p>
              <a href="https://www.linkedin.com/in/homegnome/" target="_blank" rel="noopener noreferrer" className={linkStyle}>LinkedIn Profile</a>
            </div>
          </div>

          <div className={teamMemberStyle}>
            <img src={`${process.env.PUBLIC_URL}/Seb.jpeg`} alt="Sebastian" className={imageStyle} />
            <div>
              <h3 className={subheadingStyle}>Sebastian Sanchez (he/him/el)</h3>
              <h4 className="text-lg font-medium mb-2 text-gray-700">Co-Founder & Head of Customer Experience</h4>
              <p className={paragraphStyle}>
                The charming guy who helped Vikrant find his first home, Seb is now our go-to expert for making sure every customer feels like they've hit the jackpot. With a Master's in Executive Global Management from the London School of Economics and years of real estate experience, Seb knows exactly how to deliver exceptional service. He's all about making sure homeowners get the support they need, without the hassle.
              </p>
              <a href="https://www.linkedin.com/in/sanchezsebastian/" target="_blank" rel="noopener noreferrer" className={linkStyle}>LinkedIn Profile</a>
            </div>
          </div>
        </div>

        <div className={sectionStyle}>
          <h2 className={headingStyle}>Our Story</h2>
          <p className={paragraphStyle}>
            It all started with a house. When Vikrant was buying his first home, Seb was the realtor, and Nicole was the inspector. Together, they realized just how tricky homeownership can be—and from that experience, Home Pro was born. We're here to take the guesswork out of managing your home, with a platform that helps you stay informed, organized, and confident about every repair and upgrade.
          </p>
        </div>

        <div className={sectionStyle}>
          <h2 className={headingStyle}>Our Vision</h2>
          <p className={paragraphStyle}>
            We believe that owning a home shouldn't feel like a full-time job. Whether you're dealing with minor fixes or planning a big renovation, Home Pro is here to guide you through every step, with expert advice and a little bit of wit to make it less overwhelming. Think of us as your friendly, always-on-call home support team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;