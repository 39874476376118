import React from "react";


const UserHomeProjects = () => {
    return (
    <div>
        <h1>Projects you want to do for your home comes here</h1>
        <p> We are working on it</p>
    </div>
    );
}

export default UserHomeProjects;
