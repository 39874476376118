import React from "react";


const UserHomeDetails = () => {
    return (
    <div>
        <h1  className='bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text text-center py-3 font-bold text-6xl'>Your home details!</h1>
        <p className=' text-center py-3 font-bold text-4xl'> No Home Found! Please add now</p>
        <p className=' text-center py-3 font-bold text-4xl'> Its easy, safe and convinient, See a demo!</p>
    </div>
    );
}

export default UserHomeDetails;
