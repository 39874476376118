import React from 'react';

const Product = () => {
  const sectionStyle = "mb-12 flex flex-col md:flex-row items-center md:items-start gap-8";
  const contentStyle = "md:w-1/2";
  const headingStyle = "text-2xl font-bold mb-4 text-gray-800";
  const paragraphStyle = "mb-4 text-gray-600";
  const imageStyle = "w-full max-w-md h-auto object-cover rounded-lg shadow-md";

  const handleRequestDemo = (e) => {
    e.preventDefault();
    window.location.href = "mailto:vikrant@home-owners.tech?subject=Request%20for%20HomePro%20Demo&body=I'm%20interested%20in%20seeing%20a%20demo%20of%20HomePro.%20Please%20contact%20me%20with%20more%20information.";
  };

  return (
    <div className="min-h-screen bg-stone-300 flex justify-center items-center px-4 py-8">
      <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold mb-12 text-center text-gray-800">HomePro: Your Smart Home Management Solution</h1>

        <div className={sectionStyle}>
          <div className={contentStyle}>
            <h2 className={headingStyle}>Meet Sarah, a New Homeowner</h2>
            <p className={paragraphStyle}>
              Sarah just moved into her dream home—until she spots a small leak in kitchen sink. Should she call a plumber immediately or wait and see if the leak magically fixes itself? Last time she called a contractor, she ended up paying a king's ransom for what turned out to be a two-minute fix.
            </p>
          </div>
          <img src={`${process.env.PUBLIC_URL}/product1.webp`} alt="HomePro overview" className={imageStyle} />
        </div>

        <div className={sectionStyle}>
          <img src={`${process.env.PUBLIC_URL}/product2.webp`} alt="HomePro features" className={imageStyle} />
          <div className={contentStyle}>
            <h2 className={headingStyle}>Connect with Kiona Through HomePro</h2>
            <p className={paragraphStyle}>
              Enter HomePro, where Sarah quickly connects with Kiona—her dedicated contact and an experienced architect and home inspector. Think of Kiona as the homeownership superhero, ready to swoop in with advice. She reassures Sarah that the leak can wait, reminding her that it won't start a swimming pool in the kitchen, and helps her find a trustworthy contractor to save her some serious cash.
            </p>
          </div>
        </div>

        <div className={sectionStyle}>
          <div className={contentStyle}>
            <h2 className={headingStyle}>Smart AI Assistance</h2>
            <p className={paragraphStyle}>
              HomePro's AI is like having a sidekick that crunches numbers and analyzes Sarah's home situation. It provides Kiona with data-driven insights on repair urgency and cost, so they can make informed decisions—like knowing when to fix the leak before it becomes a fountain.
            </p>
            <h2 className={headingStyle}>All Your Home Info in One Place</h2>
            <p className={paragraphStyle}>
              Through HomePro, Sarah can securely store all the details about her home's repair needs, property layout, and other juicy tidbits. So when future issues arise, she's armed and ready, with everything organized and accessible—no more digging through old takeout menus for that elusive repair list!
            </p>
          </div>
          <img src={`${process.env.PUBLIC_URL}/product3.webp`} alt="HomePro benefits" className={imageStyle} />
        </div>

        <div className={sectionStyle}>
          <div className={contentStyle}>
            <h2 className={headingStyle}>Want to See a Demo?</h2>
            <p className={paragraphStyle}>
              Curious to see how HomePro can turn your homeownership journey into a breeze? Reach out to us, and we'll be happy to give you a demo that won't leak!
            </p>
          </div>
        </div>

        <div className="mt-8 text-center">
          <button 
            onClick={handleRequestDemo}
            className="px-8 py-3 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition duration-300 shadow-md"
          >
            Request a Demo
          </button>
        </div>
      </div>
    </div>
  );
};

export default Product;