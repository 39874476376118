import React from "react";


const UserContacts = () => {
    return (
    <div>
        <h1>Your contacts come here</h1>
        <p> We are working on it</p>
    </div>
    );
}

export default UserContacts;
