import React from "react";
import { useState, useEffect } from "react";
//import axios from "axios";
const OpenAI = require("openai");


//import TypingAnimation from "../components/TypingAnimation";

const UserAIAssistant = () => {
  const [inputValue, setInputValue] = useState("");
  const [chatLog, setChatLog] = useState([]);
  

  //const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = (event) => {
        event.preventDefault();
        setChatLog((prevChatLog) => [...prevChatLog, { type: 'user', message: inputValue }])
        sendMessageToOpenAI(inputValue);
        
        setInputValue('');
    }

    const sendMessageToOpenAI = async (message) => {
        const openai = new OpenAI({
            apiKey: "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",
            dangerouslyAllowBrowser: true
        });
        const completion = await openai.chat.completions.create({
            messages: [{"role": "user", "content": message}],
            model: "gpt-3.5-turbo",
        });
        console.log("11111", completion)
        setChatLog((prevChatLog) => [...prevChatLog, { type: 'bot', message: completion.choices[0].message.content }])
    }


    

//   const handleSubmit = (event) => {
//     // event.preventDefault();
//     // setChatLog((prevChatLog) => [...prevChatLog, { type: 'user', message: inputValue }])
//     // sendMessage(inputValue);
//     // setInputValue('');
//   };

  const sendMessage = (message) => {
    // const url = '/api/chat';
    // const data = {
    //   model: "gpt-3.5-turbo-0301",
    //   messages: [{ "role": "user", "content": message }]
    // };
    // setIsLoading(true);
    // axios.post(url, data).then((response) => {
    //   console.log(response);
    //   setChatLog((prevChatLog) => [...prevChatLog, { type: 'bot', message: response.data.choices[0].message.content }])
    //   setIsLoading(false);
    // }).catch((error) => {
    //   setIsLoading(false);
    //   console.log(error);
    // })
  };
  return (
    <div class='grid grid-cols-2'>
      <h1 className='bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text text-center py-3 font-bold text-6xl'>
        AI Home Expert
      </h1>
      <div className='flex-grow p-6'>
        <div className='flex flex-col space-y-4'>
          {chatLog.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.type === "user" ? "justify-end" : "justify-start"
              }`}
            >
              <div
                className={`${
                  message.type === "user" ? "bg-purple-500" : "bg-gray-800"
                } rounded-lg p-4 text-white max-w-sm`}
              >
                {message.message}
              </div>
            </div>
          ))}
        </div>
      </div>
      <form onSubmit={handleSubmit} className='flex-none p-6'>
        <div className='flex rounded-lg border border-gray-700 bg-gray-800'>
          <input
            type='text'
            className='flex-grow px-4 py-2 bg-transparent text-white focus:outline-none'
            placeholder='Type your message...'
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <button
            type='submit'
            className='bg-purple-500 rounded-lg px-4 py-2 text-white font-semibold focus:outline-none hover:bg-purple-600 transition-colors duration-300'
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserAIAssistant;
