import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyBGDRmTdzTmjN4jaCzZ9uREikMm-9SaisQ",
  authDomain: "home-owners-tech.firebaseapp.com",
  projectId: "home-owners-tech",
  storageBucket: "home-owners-tech.appspot.com",
  messagingSenderId: "163250332793",
  appId: "1:163250332793:web:195497435b39f4cb92e0a8",
};

var app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
